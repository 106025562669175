

























































import { Component, Vue } from "vue-property-decorator";
import ExpressionOfInterestFormFields from "@/components/common/ExpressionOfInterestFormFields.vue";
import ExpressionOfInterestFormFieldsV2 from "@/components/common/ExpressionOfInterestFormFieldsV2.vue";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";
import { getFirstQueryValue } from "@/utils/queryParams";
import { ExpressionOfInterestFormQuestion } from "@/entity/ExpressionOfInterest";
import WebsiteApiService from "@/services/WebsiteApiService";
import { ContactTypeId } from "@/entity/Contact";
import TokenService from "@/services/TokenService";

enum Status {
  LOADING = "LOADING",
  READY = "READY",
  INVALID_TOKEN = "INVALID_TOKEN",
  SUBMITTING = "SUBMITTING",
  SUBMITTED = "SUBMITTED",
}

@Component({
  components: {
    AppFormWrapper,
    ExpressionOfInterestFormFields,
    ExpressionOfInterestFormFieldsV2,
  },
})
export default class ExpressionOfInterestView extends Vue {
  currentStatus = Status.LOADING;
  Status = Status;
  contactTypeId: ContactTypeId = ContactTypeId.contact;

  questions: ExpressionOfInterestFormQuestion[] = [];
  answers: Record<number, string> = {};

  attachedFiles: File[] = [];

  async handleSubmit(): Promise<void> {
    try {
      this.currentStatus = Status.SUBMITTING;
      const payload: { questionId: number; value: string }[] = Object.keys(
        this.answers
      ).map((item) => ({
        questionId: Number(item),
        value: this.answers[Number(item)],
      }));
      if (this.attachedFiles) {
        await Promise.all(
          this.attachedFiles.map((file) =>
            WebsiteApiService.uploadEoiFile(this.token, file)
          )
        );
      }
      await WebsiteApiService.uploadExpressionOfInterestV2(this.token, payload);
      this.currentStatus = Status.SUBMITTED;
    } catch (e) {
      this.currentStatus = Status.READY;
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    }
  }

  get showForm(): boolean {
    const allowedStatuses = [Status.READY, Status.SUBMITTING];
    return allowedStatuses.includes(this.currentStatus);
  }

  get token(): string {
    return getFirstQueryValue(this.$route.query.token) ?? "";
  }

  get responseMessage(): string | null {
    switch (this.currentStatus) {
      case Status.INVALID_TOKEN:
        return this.$t("tokenIsNotValid").toString();
      case Status.SUBMITTED:
        return this.$t("expressionOfInterestSubmitted").toString();
      default:
        return null;
    }
  }

  async created() {
    try {
      const response = await TokenService.isValid(this.token);
      this.currentStatus = response.expired
        ? Status.INVALID_TOKEN
        : Status.READY;
      if (this.currentStatus === Status.READY) {
        const [eoiRes, buyer] = await Promise.all([
          WebsiteApiService.getExpressionOfInterestV2(this.token),
          WebsiteApiService.getBuyerFromEoiToken(this.token),
        ]);
        this.questions = eoiRes.sort((a, b) => a.orderNum - b.orderNum);
        this.answers = eoiRes.reduce((acc, item) => {
          let value = "";
          if (item.secondaryText === "Name")
            value = buyer.contact?.firstName || "";
          if (item.secondaryText === "Vorname")
            value = buyer.contact?.lastName || "";
          return { ...acc, [item.id]: value };
        }, {});
      }
      if (response.meta.contactTypeId !== undefined) {
        this.contactTypeId = parseInt(response.meta.contactTypeId);
      }
    } catch (e) {
      this.currentStatus = Status.INVALID_TOKEN;
    }
  }
}
